<template>
  <label
    class="container group block h-3 w-3 relative cursor-pointer select-none"
    :class="{
      'h-[20px] w-[20px]': large,
      'h-[15px] w-[15px]': medium
    }"
  >
    {{ label }}
    <input
      v-model="checked"
      class="absolute opacity-0 cursor-pointer h-0 w-0"
      type="checkbox"
      :disabled="disabled"
      @change="$emit('check', checked)"
    />
    <span
      class="checkmark absolute top-0 left-0 h-full w-full border-2 border-etBlue-80 bg-white rounded-[50%] box-content after:absolute after:hidden after:top-[25%] after:left-[24%] after:w-[6px] after:h-[6px] after:rounded-[50%] after:bg-white group-hover:bg-etBlue-80 group-hover:after:block"
      :class="{
        'rounded-none': square,
        'after:w-[10.5px] after:h-[10.5px]': large,
        'after:w-[8px] after:h-[8px]': medium,
        '!bg-etBlue-80 after:!block': checked
      }"
    ></span>
  </label>
</template>
<script setup>
import { ref } from 'vue'
const emit = defineEmits(['check'])
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  medium: Boolean,
  large: Boolean,
  square: Boolean,
  active: Boolean,
  disabled: Boolean
})

const checked = ref(props.active)
</script>
